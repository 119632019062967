<template>
  <Header>
    <div el="logo">
      <!-- <span
        class="material-icons menu-btn"
        @click="$store.commit('toggleNavState')"
        >menu</span
      > -->
      <svg
        xmlns="http://www.w3.org/2000/svg"
        @click="$store.commit('toggleNavState')"
        class="h-6 w-6 menu-btn"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        style="height: 32px; color:#222;"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M4 6h16M4 12h8m-8 6h16"
        />
      </svg>
      <img src="../../assets/logo/alphaleaf_logo_color.svg" id="mobileLogo" alt="logo" el="logo" v-if="window.width > 600">
      <img src="../../assets/logo/alphaleaf_logo_brandmark_color.svg" id="mobileLogo" alt="logo" el="logo" v-else>
      
    </div>
    <div></div>
    <div class="options" el="list-column" style="--gap: 16px; align-items:center;">
      <!-- <span class="material-icons help-icon">help_outline</span>
                    <span class="material-icons notify-icon">notifications</span> -->
                    <Btn @click="open('/library')">Notes</Btn>
      <!-- <div class="dropdown" @click="$store.commit('toggleDropdown','groupDropdown')" v-if="$store.getters.isAdmin"> -->
      <div class="dropdown" @click="$store.commit('toggleDropdown','groupDropdown')" v-if="user.groups != null && user.groups.length > 1">
        <List el="column xsm" style="align-items:center;--bg-color:#f1f1f1;padding:0px 4px 0px 10px;border-radius:5px;user-select:none;cursor:pointer;">
          {{groups.active}}
          <span class="material-icons accountIcon">expand_more</span>
        </List>
        <div class="menu" v-if="header.groupDropdown">
          <div>Select Group</div>
          <!-- <hr class="none" el style="margin:8px 0;">
                            <div><span class="material-icons">account_box</span>My Profile</div>
                            <div><span class="material-icons">people</span>User Accounts</div>
                            <div><span class="material-icons">settings</span>Settings</div> -->
          <hr class="none" el style="margin: 8px 0" />
          <div @click="$store.commit('setGroup', item)" v-for="item in user.groups" :key="item">{{item}}</div>
        </div>
      </div>
      <a class="material-icons accountIcon" href="https://alphaleaftype.pages.dev/" target="_blank" style="text-decoration:none;">keyboard</a>
      <!-- <a class="material-icons accountIcon" href="https://type-fu.com/app" target="_blank" style="text-decoration:none;">keyboard</a> -->
      <span class="material-icons accountIcon" @click="to('/vscode')">view_in_ar</span>
      <div class="dropdown" @click="$store.commit('toggleDropdown','accountDropdown')">
        <!-- <img
          v-if="auth.currentUser != null && auth.currentUser.photoURL != null"
          :src="auth.currentUser.photoURL"
          alt="profile"
          class="profileImg"
          loading="lazy"
        /> -->
        <!-- <img
          v-else
          src="../../assets/img/ai_profile.jpg"
          alt="profile"
          class="profileImg"
          loading="lazy"
        /> -->
        <span class="material-icons accountIcon">account_box</span>

        <div class="menu" v-if="header.accountDropdown">
          <div>{{ auth.currentUser.displayName }}</div>
          <!-- <hr class="none" el style="margin:8px 0;">
                            <div><span class="material-icons">account_box</span>My Profile</div>
                            <div><span class="material-icons">people</span>User Accounts</div>
                            <div><span class="material-icons">settings</span>Settings</div> -->
          <hr class="none" el style="margin: 8px 0" />
          <div @click="to('/dashboard/account')">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" style="height: 20px">
            <path stroke-linecap="round" stroke-linejoin="round" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
            My Account
          </div>
          <div @click="$store.commit('signOut')">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              style="height: 20px"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
              />
            </svg>
            Sign Out
          </div>
        </div>
      </div>
    </div>
  </Header>
</template>
<script>
import { auth } from "@/firebase";
import router from "@/router";
import List from "../../../design-system/components/vue/List.vue";

export default {
  components: {
    List
},
  data() {
    return {
      window: this.$store.state.main.window,
      // user: this.$store.state.main.user,
      groups: this.$store.state.main.groups,
      header: this.$store.state.main.layout.header,
      auth: auth,
    };
  },
  methods: {
    to(path){
      router.push(path);
    },
    open(url){
      window.open(url, '_blank')
    },
    signOut() {
      this.$store.commit("signOut");
    },
  },
  computed:{
    user(){
      return this.$store.state.main.user;
    },
    filteredGroupsList(){
      let x = this;
      return x.user.groups;
    }
  }
};
</script>
<style lang="scss" scoped>
[el="header"] {
  --bg-color: transparent;
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  grid-gap: 16px;
  align-items: center;
  padding: 0 30px;
}
[el="header"] [el="logo"] {
  fill: #fff;
  height: 43px;
  display: grid;
  grid-gap: 16px;
  grid-auto-flow: column;
  align-items: center;
}
[el="header"] > .options {
  align-items: center;
}
[el="header"] [el="logo"] .material-icons,
.help-icon,
.notify-icon {
  color: hsla(0, 0%, 100%, 0.65);
  -webkit-text-fill-color: currentColor;
  transition: background-color 0.2s;
  cursor: pointer;
}
[el="header"] [el="logo"] .material-icons:hover,
.help-icon:hover,
.notify-icon:hover {
  color: hsla(0, 0%, 100%, 1);
}
.menu-btn {
  padding: 3px 5px;
  border-radius: 3px;
  transition: 0.2s;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  color: hsla(0, 0%, 100%, 0.4);
}
.menu-btn:hover {
  background-color: hsla(0, 0%, 0%, 0.25);
  color: hsla(0, 0%, 100%, 1);
}
.profileImg,
.navProfileImg {
  --size: 32px;
  width: var(--size);
  height: var(--size);
  background-color: #f1f1f1;
  border-radius: 100%;
  object-fit: cover;
  object-position: center;
  box-shadow: 0 0 0 1px hsla(0, 0%, 100%, 0.65);
  cursor: pointer;
}
.navProfileImg {
  --size: 112px;
  transition: 0.2s;
}
[el="aside"].collapse .navProfileImg {
  --size: 32px;
}
.dropdown {
  position: relative;
}
.dropdown .menu {
  position: absolute;
  right: 0;
  background-color: #fff;
  top: 100%;
  z-index: 5;
  min-height: 100px;
  width: 250px;
  box-shadow: 0 3px 8px hsla(0, 0%, 0%, 0.05);
  border-radius: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  border: 3px solid var(--color-13);
}
.dropdown .menu > *:not(.none) {
  padding: 8px 16px;
  cursor: pointer;
  align-items: center;
  display: grid;
  grid-gap: 8px;
  grid-auto-flow: column;
  justify-content: start;
  color: var(--color-65);
}
.dropdown .menu > * .material-icons {
  font-size: 24px;
}
.dropdown .menu > *:not(.none):hover {
  background-color: rgba(0, 0, 0, 0.07);
  color: var(--color-85);
}
.accountIcon{
  color:var(--color-ocean);
  transition:0.2s;
  font-size:30px;
  cursor:pointer;
  user-select:none;
}
.accountIcon:hover{
  color:#222;
}
</style>
