<template>
  <Layout el="ham fixed">
    <Header />
    <Aside />
    <main el="main">
      <router-view></router-view>
    </main>
    <Section />
  </Layout>
</template>

<script>
// @ is an alias to /src
// let _ = require("lodash");
import Header from "../components/dashboard/Header.vue";
import Aside from "../components/dashboard/Aside.vue";
import Section from "../components/dashboard/Section.vue";

export default {
  components: {
    Header,
    Aside,
    Section,
  },
  data() {
    return {
      module: {
        label: "",
        img: "",
        description: "",
        content: [],
      },
    };
  },
  methods: {},
  computed: {},
  watch: {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
[el~="layout-ham"] {
  --layout-columns: [header-start aside] max-content [main] 1fr [section]
    max-content [header-end];
  --layout-rows: [header] minmax(65px, max-content) [aside main section] 1fr;
}
[el="main"] {
  --bg-color: var(--color-7);
  border-radius: 24px 0 0 0;
}
[el="main"] > * {
  padding: 64px;
}
.lgSection{
  max-width:800px;
}

@media (max-width: 600px) {
  [el="main"] > * {
    padding: 64px 20px;
  }
  [el="section"] {
    width: 100vw;
  }
  [el="main"] > * {
    padding: 20px;
  }
}
</style>
