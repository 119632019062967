<template>
  <aside el="aside" :class="{ collapse: nav.collapse }">
    <div class="profile-container" el="list">
      <img
        v-if="auth.currentUser != null && auth.currentUser.photoURL != null"
        :src="auth.currentUser.photoURL"
        alt="profile"
        class="navProfileImg"
        loading="lazy"
      />
      <img
        v-else
        src="../../assets/img/ai_profile.jpg"
        alt="profile"
        class="navProfileImg"
        loading="lazy"
      />
      <!-- <p el="bold" v-if="!nav.collapse">Julia Smith</p> -->
    </div>
    <nav>
      <div
        el="list-column"
        :class="{ 'nav-link': true, active: nav.active == item.key }"
        v-for="item in filteredNav"
        :key="item"
        @click="$store.commit('navTo', item.key)"
      >
        <span class="material-icons" v-if="item.key != 'modules'">{{
          item.icon
        }}</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          style="height: 24px"
          v-else
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"
          />
        </svg>

        <span style="font-weight: 700;" v-if="!nav.collapse">{{
          item.label
        }}</span>
      </div>
    </nav>
  </aside>
</template>
<script>
import { auth } from "@/firebase";
// let filter = require("lodash/filter");
export default {
  components: {
    // Layout,
  },
  data() {
    return {
      window: this.$store.state.main.window,
      user: this.$store.state.main.user,
      nav: this.$store.state.main.layout.nav,
      auth: auth,
    };
  },
  computed: {
    filteredNav() {
      const isAdmin = this.$store.getters.isAdmin;
      if (isAdmin) {
        return this.$store.getters.adminNavList;
      } else {
        return this.$store.getters.navList;
      }
    },
  },
  mounted() {
    this.$store.commit("activateNavPath");
  },
};
</script>
<style lang="scss" scoped>
[el="aside"] {
  width: 250px;
  padding-top: 30px;
  display: grid;
  grid-gap: 24px;
  align-content: flex-start;
  transition: width 0.2s;
}
[el="aside"].collapse {
  width: 55px;
}
.profile-container {
  display: grid;
  justify-content: center;
  justify-items: center;
}
@media (max-width: 600px) {
  [el="aside"] {
    width: 100vw;
  }
  [el="aside"].collapse {
    width: 0px;
  }
}
.navProfileImg {
  --size: 32px;
  width: var(--size);
  height: var(--size);
  background-color: #f1f1f1;
  border-radius: 100%;
  object-fit: cover;
  object-position: center;
  box-shadow: 0 0 0 1px hsla(0, 0%, 100%, 0.65);
  cursor: pointer;
      border: 3px solid var(--color-13);
}
.navProfileImg {
  --size: 112px;
  transition: 0.3s;
}
[el="aside"].collapse .navProfileImg {
  --size: 32px;
}
.nav-link {
  padding: 10px 16px;
  font-weight: var(--font-bold) !important;
  --color: var(--color-b80);
  transition: color 0.2s;
  cursor: pointer;
  align-items: center;
  --gap: 16px;
  user-select: none;
}
.nav-link.active {
  --color: var(--color-ocean);
  background-color: rgba(0, 0, 0, 0.07);
  // box-shadow: 5px 0px 0px var(--color-ocean) inset;
}
.nav-link:hover {
  background-color: rgba(0, 0, 0, 0.07);
  color: var(--color-ocean);
}
</style>
