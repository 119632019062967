<template>
  <section
    el="section"
    :class="{
      collapse: section.collapse,
      lgPanel: lgPanel,
    }"
  >
    <router-view name="section"></router-view>
  </section>
</template>
<script>
export default {
  components: {
    // Layout,
  },
  data() {
    return {
      window: this.$store.state.main.window,
      user: this.$store.state.main.user,
      section: this.$store.state.main.layout.section,
      lgPanel: false,
    };
  },
  methods: {
    checkSubSection(section) {
      return this.section.active == section ? true : false;
    },
  },
  watch:{
    'section.collapse':function(){
      let path = this.$router.currentRoute.value.fullPath;
      if(path.startsWith('/dashboard/files')){
        this.lgPanel = true;
      }else{
        this.lgPanel = false;
      }
    }
  },
  mounted() {
    if (this.$route.params.id != null) {
      this.section.collapse = false;
    }
  },
};
</script>
<style lang="scss" scoped>
[el="section"] {
  width: 400px;
  transition: width 0.2s;
      border-radius: 12px 12px 0 0;
    border: 3px solid var(--color-13);
    margin-top: -12px;
}
[el="section"].lgPanel {
  width: 700px;
}

@media (max-width: 600px) {
  [el="section"] {
    width: 100vw;
  }
}
[el="section"].collapse {
  width: 0;
}
</style>
